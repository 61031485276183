import { useEffect, useState } from "react";
import api from "../services/api";
import HeaderAdmin from "../components/HeaderAdmin";
import WizardModal from "../components/WizardModal";
import { useAuth } from "../context/useAuth";
import Moment from "react-moment";
import useTracking from "../services/useTracking";
import { Link, useNavigate } from "react-router-dom";
import writing from "../images/writing.gif";
import uploadCompleted from "../images/upload_completed.gif";

export default function AdminBlog() {
  const [posts, setPosts] = useState();
  const [showWizardModal, setShowWizardModal] = useState();
  const [showCompletedPost, setShowCompletedPost] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState();
  let { user } = useAuth();
  const tracking = useTracking("Blog List");

  useEffect(() => {
    fetchPost();
  }, []);

  // useEffect(() => {
  //   if (!user.settings || !user.settings.blogDescription) setShowWizardModal(true);
  // }, [user]);

  const fetchPost = async () => {
    let posts = await api.getBlogPosts();
    if (posts.status === 200) setPosts(posts.data);
  };

  const addPost = () => {
    navigate("/post/create");
  };

  const closeWizardModal = () => {
    setShowWizardModal(false);
  };

  // const importPosts = async () => {
  //   const text = await navigator.clipboard.readText();
  //   await api.importPosts(text.split("\n"));
  //   fetchPost();
  // };

  const createRandom = async () => {
    tracking("Create Random Post");
    if (!user.settings || !user.settings.blogDescription) return setShowWizardModal(true);

    setLoading(true);
    let res = api.createRandom();
    setTimeout(() => {
      fetchPost();
    }, 3000);

    res.then((res) => {
      setLoading(false);
      if (res.status === 200) {
        fetchPost();
        setShowCompletedPost(res.data._id);
        fetchPost();
      } else {
        alert("Error");
      }
    });
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="admin-container">
        <h2>Blog Posts</h2>

        <div className="btn-bar">
          <div className="btn-bar-left">
            <button onClick={createRandom}>Create Post With AI</button>
            <button onClick={addPost} className="link">
              Add Post Manually
            </button>
          </div>
        </div>

        {posts && (
          <>
            <div className="table-container">
              <table>
                <thead>
                  <tr>
                    <td>Post Title</td>
                    <td style={{ width: 100 }}>Publish Date</td>
                  </tr>
                </thead>
                <tbody>
                  {posts.map((post, i) => (
                    <tr key={i}>
                      <td>
                        {post.elaborating ? (
                          <div style={{ display: "flex" }}>
                            <div className="badge-elaborating">
                              <img
                                src={writing}
                                style={{ height: 25, padding: 0, margin: -15, marginLeft: -13, marginRight: 10 }}
                                alt="upload-complete"
                              ></img>
                              Writing post, please wait
                            </div>
                            {/* eslint-disable-next-line */}
                            <a onClick={() => alert("Please wait for the end of elaboration")}>{post.title}</a>
                          </div>
                        ) : (
                          <>
                            <Link to={"/post/" + post._id}>{post.title}</Link>
                          </>
                        )}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {post.publishedDate ? <Moment format="MM-DD-YYYY">{post.publishedDate}</Moment> : ""}
                      </td>
                    </tr>
                  ))}

                  {posts.length === 0 && (
                    <tr>
                      <td colSpan="2">
                        <div style={{ padding: "5px 15px" }}>No Articles Found</div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}

        {showWizardModal && (
          <WizardModal close={closeWizardModal} fetchPost={fetchPost} setShowCompletedPost={setShowCompletedPost}></WizardModal>
        )}
        {loading && (
          <div className="modal-backdrop">
            <div className="modal">
              <div className="modal-center">
                <h4>Our AI is writing your post</h4>
                <p>It may take up to 60 seconds, please wait</p>
                <img style={{ width: "80%", "max-width": "200px" }} src={writing} alt="writing"></img>
                <p>This page will be automatically refreshed when the post is ready</p>

                <button
                  onClick={() => {
                    fetchPost();
                    setLoading(false);
                  }}
                >
                  Hide
                </button>
              </div>
            </div>
          </div>
        )}
        {showCompletedPost && (
          <div className="modal-backdrop">
            <div className="modal">
              <div className="modal-center">
                <h4>Post elaboration is completed</h4>

                <img loop={false} src={uploadCompleted} style={{ width: 150, marginBottom: 30 }} alt="upload-complete"></img>

                <button
                  onClick={() => {
                    navigate("/post/" + showCompletedPost);
                  }}
                >
                  View Post
                </button>

                <button
                  className="link"
                  onClick={() => {
                    fetchPost();
                    setShowCompletedPost(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
