import info from "../images/info.svg";

export default function InfoIcon({ text }) {
  return (
    <div className="info-icon-container">
      <img className="info-icon" src={info} alt="info"></img>
      <div className="info-icon-text">{text}</div>
    </div>
  );
}
