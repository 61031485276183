import { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.svg";
import { GoogleLogin } from "@react-oauth/google";

// Context
import { useAuth } from "../context/useAuth";
import { Link } from "react-router-dom";

const TITLE = "Create Blog AI - App";

export default function Submit() {
  let auth = useAuth();
  const [error, setError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginFn = async () => {
    if (!(await auth.login(username, password))) setError(true);
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-container">
        <img className="login-logo" src={logo} alt="Logo"></img>
        <h1>Create Blog AI</h1>
        <h3>Login into your account</h3>
        <div className="login-form">
          <input onChange={(e) => setUsername(e.target.value)} placeholder="username"></input>
          <input onChange={(e) => setPassword(e.target.value)} placeholder="password" type="password"></input>
        </div>
        <button className="big" onClick={() => loginFn()}>
          Login
        </button>

        <h4>OR</h4>

        <GoogleLogin
          onSuccess={(credentialResponse) => {
            auth.successLoginGoogle(credentialResponse);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />

        {error && <div className="error mt-15">Login not valid</div>}

        <Link className="mt-15" to="/resetPassword">
          Reset Password
        </Link>
      </div>
    </>
  );
}
