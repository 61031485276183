import { useState } from "react";
import api from "../services/api";
import { useAuth } from "../context/useAuth";
import useTracking from "../services/useTracking";
import writing from "../images/writing.gif";
import logo from "../images/logo.svg";
import InfoIcon from "./InfoIcon";

export default function WizardModal({ close, fetchPost, setShowCompletedPost }) {
  let { user, checkAuth } = useAuth();
  let [settings, setSettings] = useState(user.settings || {});
  let [showError, setShowError] = useState(false);
  let [page, setPage] = useState(1);
  const tracking = useTracking("Wizard page");

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setShowError(!value);
    setSettings({ ...settings, ...newObj });
  };

  const save = async () => {
    setShowError(false);
    if (!settings.blogDescription) {
      setShowError(true);
    } else {
      let data = await api.saveSettings(settings);
      if (data.status === 200) {
        checkAuth();
        // setPage(2);
        createRandom();
      }
    }
  };

  const createRandom = async () => {
    tracking("Create First Post");
    setPage(3);
    let res = api.createRandom();
    setTimeout(() => {
      fetchPost();
    }, 3000);

    res.then((res) => {
      if (res.status === 200) {
        close();
        fetchPost();
        setShowCompletedPost(res.data._id);
      } else {
        alert("Error");
      }
    });
  };

  return (
    <>
      <div className="modal-backdrop">
        <div className="modal modal-wizard full-mobile">
          {/* {loading && <div>Loading...</div>} */}

          <div className="modal-center">
            <div className="wizard-logo">
              <img className="wizard-logo-img" src={logo} alt="Logo"></img>
              <span className="wizard-logo-text">Create Blog AI</span>
            </div>
            {page === 1 && (
              <>
                <h3>
                  <InfoIcon
                    text={`The next blog posts will be created based on this topic. 
                As example if you want to talk about travels in your blog you can insert "Trips and travels" or you can even be
                more specific writing "traveling in mountains".

                You can change the blog description later on from the setting page.`}
                  ></InfoIcon>
                  What do you want to write about?
                </h3>
                <input
                  placeholder="Insert here the topic of your blog"
                  value={settings.blogDescription}
                  onChange={(e) => setValue("blogDescription", e.target.value)}
                ></input>
                {showError && <p className="alert-error">Please insert a short blog description</p>}

                <div className="btn-bar">
                  <button className="big" onClick={save}>
                    Create my first post
                  </button>
                </div>
              </>
            )}
            {page === 2 && (
              <>
                <h3>Great! Now you can create your first post</h3>

                <button onClick={createRandom}>Create Post With AI</button>
              </>
            )}
            {page === 3 && (
              <>
                <h4 style={{ marginTop: 0, marginBottom: 0 }}>Our AI is writing your post</h4>
                <p>It may take up to 60 seconds, please wait</p>
                <img style={{ width: "80%", "max-width": "200px" }} src={writing} alt="writing"></img>
                <p>This page will be automatically refreshed when the post is ready</p>

                <button
                  onClick={() => {
                    fetchPost();
                    close();
                  }}
                >
                  Hide
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
