import { Link } from "react-router-dom";
import hamburger from "../images/hamburger.svg";
import logo from "../images/logo.svg";
import useTracking from "../services/useTracking";
import { useState } from "react";

// Context
import { useAuth } from "../context/useAuth";

export default function Navbar({ transparent }) {
  const tracking = useTracking("Navbar");
  const [showMenu, setShowMenu] = useState(false);
  const auth = useAuth();

  const triggerMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className={"navbar navbar-admin" + (showMenu ? " open" : "")}>
      <div className="navbar-left">
        <Link
          to="/"
          onClick={() => {
            tracking("Click on logo from Navbar");
          }}
        >
          <img className="navbar-logo" src={logo} alt="Logo"></img>
          <span className="navbar-logo-text">Create Blog AI</span>
        </Link>
      </div>

      <div className="navbar-center"></div>

      <div className="navbar-burger-content">
        <img onClick={() => triggerMenu()} className="navbar-burger" src={hamburger} alt="hamburger"></img>
      </div>

      <div className={"navbar-right" + (showMenu ? " open" : "")}>
        <div className="navbar-btn">
          <Link
            to="/"
            onClick={() => {
              tracking("Click on Blog Posts");
            }}
          >
            Blog Posts
          </Link>
        </div>
        <div className="navbar-btn">
          <Link
            to="/settings"
            onClick={() => {
              tracking("Click on Settings");
            }}
          >
            Settings
          </Link>
        </div>
        <div className="navbar-btn">
          {/* eslint-disable-next-line */}
          <a onClick={auth.logout} style={{ curosor: "pointer" }}>
            Logout
          </a>
        </div>
      </div>
    </div>
  );
}
