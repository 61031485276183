import { useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../images/logo.svg";
import api from "../services/api";

const TITLE = "Create Blog AI - Set Password";

export default function ResetPassword() {
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const reset = async () => {
    setMessage(false);
    setError(false);
    let result = await api.resetPasswordRequest(email);

    if (result.status === 200) {
      setMessage(true);
    } else if (result.status === 401) {
      setError("User not found");
    } else {
      setError("Error");
    }
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <div className="login-container">
        <img className="login-logo" src={logo} alt="Logo"></img>
        <h1>Create Blog AI</h1>
        <div className="login-form">
          <input onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" type="email"></input>
        </div>
        <button className="big" onClick={() => reset()}>
          Reset Password
        </button>

        {error && <div className="error mt-15">{error}</div>}
        {message && <div className="message mt-15">Check your inbox to reset the password</div>}
      </div>
    </>
  );
}
