export default function APIModal({ close }) {
  const request = String.raw`
    API Request POST body:
    
    {
        title: String,
        urlPost: String, // URL segment of the article
        content: String,
        metaDescription: String,
        keywords: [String],
        creationDate: Date,
    }


    
    API Response POST body:
    
    {
        publicUrl: String, // Absolute URL of the article
    }

        `;
  return (
    <>
      <div className="modal-backdrop">
        <div className="modal modal-wizard">
          <button className="close-btn" onClick={() => close()}>
            X
          </button>

          <div className="modal-content">
            <pre>{request}</pre>
          </div>
        </div>
      </div>
    </>
  );
}
