import { useEffect, useState } from "react";
import api from "../services/api";
import { useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../context/useAuth";

import HeaderAdmin from "../components/HeaderAdmin";
import APIModal from "../components/APIModal";
import InfoIcon from "../components/InfoIcon";

import wpIcon from "../images/wordpress.png";
import apiIcon from "../images/api_blue.png";

export default function Settings() {
  let { user, checkAuth } = useAuth();
  let [settings, setSettings] = useState(user.settings || {});
  let [saved, setSaved] = useState();
  let [openApiModal, setOpenApiModal] = useState(false);
  const { search } = useLocation();
  const navigate = useNavigate();
  let [errorUrl, setErrorUrl] = useState(false);

  useEffect(() => {
    let params = new URLSearchParams(search);
    let code = params.get("code");
    if (code) {
      saveCode(code);
    }

    let state = params.get("state");
    if (state) {
      navigate("/post/" + state);
    }

    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    setSettings(user.settings);
  }, [user]);

  const saveCode = async (code) => {
    let data = await api.saveWordpressCode(code);
    if (data.status === 200) {
      checkAuth();
    } else {
      alert("Error connecting wordpress");
    }
  };

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setSettings({ ...settings, ...newObj });
  };

  const save = async () => {
    setErrorUrl(false);

    if (settings.publishMode === "api" && !settings.urlApi) {
      setErrorUrl(true);
      return;
    }

    let data = await api.saveSettings(settings);
    if (data.status === 200) {
      checkAuth();
      setSaved(true);
      window.setTimeout(() => setSaved(false), 2000);
    }
  };

  const connectWordpress = async () => {
    window.location.href =
      "https://public-api.wordpress.com/oauth2/authorize?client_id=" +
      process.env.REACT_APP_WP_CLIENT_ID +
      "&redirect_uri=" +
      process.env.REACT_APP_WP_REDIRECT_URI +
      "&response_type=code&scope=global,posts";
  };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>
      <div className="settings-container">
        <h2>Settings</h2>

        <div className="btn-bar">
          <div className="btn-bar-left">
            <button className="big dark" onClick={save}>
              Save
            </button>
          </div>
        </div>
        {saved && <div className="alert-success">Settings saved</div>}
        {errorUrl && <div className="alert-error">Please insert all the required data</div>}

        <h3>Blog Info</h3>
        <div className="label-row">
          <label>
            Blog Description
            <InfoIcon text="This is the input parameter for the AI to generate new titles and articles"></InfoIcon>
          </label>
          <input value={settings.blogDescription} onChange={(e) => setValue("blogDescription", e.target.value)}></input>
        </div>

        <div className="label-row">
          <label>
            Receive notification e-mails
            <InfoIcon text="Receive e-mail notification when a post is created or published."></InfoIcon>
          </label>
          <input type="checkbox" checked={settings.sendEmail} onChange={(e) => setValue("sendEmail", e.target.checked)}></input>
        </div>

        <h3>Scheduling</h3>
        <div className="label-row">
          <label>
            Automatic Publish
            <InfoIcon text="The publish is planned at 10AM PT. If there are articles created and not yet published it will be published, otherwise a new one will be automatically created and published"></InfoIcon>
          </label>
          <div className="publish-mode-container">
            <div
              className={"publish-mode-option" + (!settings.scheduledPublishing ? " active" : "")}
              onClick={() => setValue("scheduledPublishing", "")}
            >
              <label>Disabled</label>
            </div>
            <div
              className={"publish-mode-option" + (settings.scheduledPublishing === "daily" ? " active" : "")}
              onClick={() => setValue("scheduledPublishing", "daily")}
            >
              <label>Daily</label>
            </div>
            <div
              className={"publish-mode-option" + (settings.scheduledPublishing === "weekly" ? " active" : "")}
              onClick={() => setValue("scheduledPublishing", "weekly")}
            >
              <label>Weekly</label>
            </div>
          </div>
        </div>

        <h3>Publish settings</h3>
        <h4>How do you want to publish your blog?</h4>

        <div className="publish-mode-container">
          <div
            className={"publish-mode-option" + (settings.publishMode === "wordpress" ? " active" : "")}
            onClick={() => setValue("publishMode", "wordpress")}
          >
            <img src={wpIcon} alt="wp"></img>
            <label>Wordpress integration</label>
          </div>
          <div
            className={"publish-mode-option" + (settings.publishMode === "api" ? " active" : "")}
            onClick={() => setValue("publishMode", "api")}
          >
            <img src={apiIcon} alt="api"></img>
            <label>Custom API</label>
          </div>
        </div>

        {settings.publishMode === "api" && (
          <div>
            <p>
              We will call your custom API at the specified URL passing{" "}
              <span
                className="link"
                style={{ marginTop: 20, marginBottom: 20, cursor: "pointer" }}
                onClick={() => setOpenApiModal(true)}
              >
                following the paramenter
              </span>
            </p>
            <p>Please ensure your custom API will publish the content on you own system</p>

            <div className="label-row">
              <label>
                URL to call
                <InfoIcon text="The URL of you custom API that will publish the article on your own system"></InfoIcon>
              </label>
              <input value={settings.urlApi} onChange={(e) => setValue("urlApi", e.target.value)}></input>
              {errorUrl && <div className="alert-error">Please insert a valid URL</div>}
            </div>

            <div className="label-row">
              <label>
                Header variable name
                <InfoIcon text="You can set this header for aithentication to your custom API"></InfoIcon>
              </label>
              <input value={settings.headerName} onChange={(e) => setValue("headerName", e.target.value)}></input>
            </div>

            <div className="label-row">
              <label>
                Header variable value
                <InfoIcon text="Value of the header variable name specified before"></InfoIcon>
              </label>
              <input value={settings.headerValue} onChange={(e) => setValue("headerValue", e.target.value)}></input>
            </div>

            {openApiModal && <APIModal close={() => setOpenApiModal(false)}></APIModal>}
          </div>
        )}
        {settings.publishMode === "wordpress" && (
          <div className="content-center">
            {user.settings.wordpressURL ? (
              <div className="label-row" style={{ marginTop: 15, marginLeft: 10 }}>
                Connected to{" "}
                <a href={user.settings.wordpressURL} target="blank" className="link">
                  {user.settings.wordpressURL}
                </a>
                <div className="label-row mt-15">
                  <button onClick={connectWordpress} className="link">
                    Re-Connect Wordpress
                  </button>
                </div>
              </div>
            ) : (
              <div className="label-row mt-15">
                <button onClick={connectWordpress}>Connect Wordpress</button>
              </div>
            )}
          </div>
        )}
      </div>

      {openApiModal && <APIModal close={() => setOpenApiModal(false)}></APIModal>}
    </>
  );
}
