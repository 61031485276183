import React, { useEffect, useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Pages
import BlogList from "./pages/BlogList";
import BlogEdit from "./pages/BlogEdit";
import ResetPassword from "./pages/ResetPassword";
import SetPassword from "./pages/SetPassword";
import Settings from "./pages/Settings";
import Login from "./pages/Login";

// Context
import { useAuth } from "./context/useAuth";

export default function RoutesApp() {
  let auth = useAuth();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    auth.checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {auth.user ? (
        <>
          <Route path="/" element={<BlogList />} />
          <Route path="/post/:id" element={<BlogEdit />} />
          <Route path="/settings" element={<Settings />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Login />} />
        </>
      )}
      <Route path="/setPassword/:id/:token" element={<SetPassword />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
    </Routes>
  );
}
