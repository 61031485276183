import { useState, cloneElement } from "react";

export default function Dropdown({ text, mainAction, menu }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="dropdown">
      <div className={"button" + (open ? " open" : "")}>
        <div onClick={mainAction}>{text}</div>
        <div className="dropdown-arrow" onClick={handleOpen}>
          {open ? <>&#9650;</> : <>&#9660;</>}
        </div>
      </div>
      {open ? (
        <div className="menu">
          {menu.map((menuItem, index) => (
            <div key={index} className="menu-item">
              {cloneElement(menuItem, {
                onClick: () => {
                  menuItem.props.onClick();
                  setOpen(false);
                },
              })}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
