import { useState, useEffect } from "react";
import api from "../services/api";
import { Link, useNavigate, useParams } from "react-router-dom";

import Dropdown from "../components/Dropdown";
import HeaderAdmin from "../components/HeaderAdmin";
import PublishModeModal from "../components/PublishModeModal";
import backArrow from "../images/back_arrow.svg";
import writing from "../images/writing.gif";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function AdminBlog() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [showPublishModeModal, setShowPublishModeModal] = useState(false);
  const [post, setPost] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(true);

  useEffect(() => {
    fetchPost();
    // eslint-disable-next-line
  }, [id]);

  const fetchPost = async () => {
    if (id !== "create") {
      let posts = await api.getBlogPost(id);
      if (posts.status === 200) {
        setPost(posts.data);
      }
    } else {
      setShowPreview(false);
    }
  };

  const setValue = (key, value) => {
    let newObj = {};
    if (key === "enabled" && value === true) {
      newObj.publishedDate = new Date();
    }
    newObj[key] = value;
    setPost({ ...post, ...newObj });
  };

  const save = async () => {
    if (!post.keywords) post.keywords = [];
    let postObj = { ...post, ...{ keywords: post.keywords.split ? post.keywords.split(",") : post.keywords } };
    let data = await api.savePost(postObj);
    if (data.status === 200) {
      navigate("/");
    }
  };

  const remove = async () => {
    if (await window.confirm("Are you sure?")) {
      let data = await api.removePost(post._id);
      if (data.status === 200) {
        navigate("/");
      }
    }
  };

  const populate = async () => {
    if (await window.confirm("This will create the article the metadata of the article based on title and content")) {
      setLoading(true);
      let data = await api.populatePost(post);
      setLoading(false);
      if (data.status === 200) {
        setPost(data.data);
        alert("SEO Info added to the post");
      } else {
        alert("Error");
      }
    }
  };

  const writeArticle = async () => {
    if (await window.confirm("This will write the article content starting from the title")) {
      setLoading(true);
      let data = await api.writePost(post);
      setLoading(false);
      if (data.status === 200) {
        setPost(data.data);
        alert("Post content has been written");
      } else {
        alert("Error");
      }
    }
  };

  const closePublishModeModal = (e) => {
    setShowPublishModeModal(false);

    if (e === "publish") {
      publish();
    }
  };

  const publish = async () => {
    if (!post._id) {
      return alert("Please save the post befor to publish it");
    }

    setShowPublishModeModal(true);
  };

  const elaborateArticle = async () => {
    if (post.title) {
      if (await window.confirm("This will create the post starting from the given title")) {
        setLoading(true);
        let data = await api.elaboratePost(post);
        setLoading(false);
        if (data.status === 200) {
          setPost(data.data);
          alert("The content and SEO info has been added to the post");
        } else {
          alert("Error");
        }
      }
    } else {
      alert("Please enter a title before to generate the content");
    }
  };

  // const selectPhoto = async () => {
  //   inputFile.current.click();
  // };

  // const uploadPhoto = async (e, type) => {
  //   setLoading(true);
  //   let img = await api.uploadPhoto(e.target.files[0], { type: "blog", name: post.title });
  //   setLoading(false);
  //   if (img.status === 200) {
  //     setValue("image", img.data.fileName);
  //   } else {
  //     alert("Error in the upload");
  //     inputFile.current.value = null;
  //     inputFile.current.file = null;
  //   }
  // };

  return (
    <>
      <HeaderAdmin></HeaderAdmin>

      <div className="edit-post-container">
        {showPreview ? (
          <>
            <div className={"navbar navbar-admin navbar-admin-preview"}>
              <div className="navbar-left">
                <button onClick={publish} className="dark">
                  Publish
                </button>
              </div>

              <div className="navbar-right-show">
                <button onClick={() => setShowPreview(false)}>Edit</button>
              </div>
            </div>

            <h2>{post.title}</h2>

            <div className="seo-metadata">
              <div className="seo-title">SEO Metadata</div>
              <div className="seo-content">
                <div>
                  <label>SEO URL</label>
                  <span>{post.urlPost}</span>
                </div>
                <div>
                  <label>Meta description</label>
                  <span>{post.metaDescription}</span>
                </div>
                <div>
                  <label>Keywords</label>
                  <span>{post.keywords && post.keywords.join(", ")}</span>
                </div>
              </div>
            </div>

            <div className="blog-post-long-description">
              <div dangerouslySetInnerHTML={{ __html: post.content }} />
            </div>
          </>
        ) : (
          <>
            <h2>
              <Link to="/" className="back-link">
                <img style={{ height: 30 }} src={backArrow} alt="back" />
              </Link>
              Post Edit
            </h2>

            <div className="btn-bar">
              <div className="btn-bar-left">
                <button onClick={publish} className="dark">
                  Publish
                </button>

                <button onClick={save}>Save</button>
                <Dropdown
                  text={"AI Tools"}
                  mainAction={elaborateArticle}
                  menu={[<div onClick={writeArticle}>Write Post Content</div>, <div onClick={populate}>Create SEO Metadata</div>]}
                />

                {/*
            <button onClick={writeArticle}>Write Article Content</button>
            <button onClick={populate}>Create Metadata</button> */}
                {/* {post._id && <button onClick={() => selectPhoto()}>Change image</button>} */}
                {/* <input type="file" ref={inputFile} onChange={uploadPhoto} style={{ display: "none" }} /> */}
              </div>
              <div className="btn-bar-right">
                {post._id && (
                  <button onClick={remove} className="dark">
                    Delete
                  </button>
                )}

                <div className="navbar-right-show">
                  <button onClick={() => setShowPreview(true)}>Preview</button>
                </div>
              </div>
            </div>

            {loading && (
              <div className="badge-elaborating" style={{ marginBottom: 20 }}>
                <img
                  src={writing}
                  style={{ height: 25, padding: 0, margin: -15, marginLeft: -13, marginRight: 10 }}
                  alt="upload-complete"
                ></img>
                Writing post, please wait
              </div>
            )}

            <div>
              <div className="label-row">
                <label>Title</label>
                <input value={post.title} onChange={(e) => setValue("title", e.target.value)}></input>
              </div>

              <h3>SEO Metadata</h3>
              <div className="label-row">
                <label>URL</label>
                <input value={post.urlPost} onChange={(e) => setValue("urlPost", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Description</label>
                <input value={post.metaDescription} onChange={(e) => setValue("metaDescription", e.target.value)}></input>
              </div>
              <div className="label-row">
                <label>Keywords</label>
                <input value={post.keywords} onChange={(e) => setValue("keywords", e.target.value)}></input>
              </div>

              <h3>Content</h3>

              <div className="container-markdown">
                {post.content && <ReactQuill theme="snow" value={post.content} onChange={(e) => setValue("content", e)} />}
              </div>
            </div>
          </>
        )}

        {showPublishModeModal && (
          <PublishModeModal close={closePublishModeModal} post={post} setPost={setPost}></PublishModeModal>
        )}
      </div>
    </>
  );
}
