import { useState } from "react";
import api from "../services/api";
import { useAuth } from "../context/useAuth";

import wpIcon from "../images/wordpress.png";
import apiIcon from "../images/api_blue.png";
import upload from "../images/upload.gif";
import uploadCompleted from "../images/upload_completed.gif";

import InfoIcon from "../components/InfoIcon";
import APIModal from "../components/APIModal";

export default function PublishModeModal({ close, post, setPost }) {
  let { user, checkAuth } = useAuth();
  let [settings, setSettings] = useState(user.settings || {});
  let [page, setPage] = useState(1);
  const [publishing, setPublishing] = useState(false);
  let [openApiModal, setOpenApiModal] = useState(false);
  let [errorUrl, setErrorUrl] = useState(false);

  const setValue = (key, value) => {
    let newObj = {};
    newObj[key] = value;
    setSettings({ ...settings, ...newObj });
  };

  const saveClose = async () => {
    let data = await api.saveSettings(settings);
    if (data.status === 200) {
      checkAuth();
      close();
    }
  };

  const save = async () => {
    setErrorUrl(false);

    if (settings.publishMode === "api" && !settings.urlApi) {
      setErrorUrl(true);
      return;
    }

    let data = await api.saveSettings(settings);
    if (data.status === 200) {
      checkAuth();
      setPage(2);
      setPublishing(true);
      let data = await api.publish(post);
      if (data.status === 200) {
        setPost(data.data);
      } else {
        setPage(1);
        alert("Error: " + (data.data.message || data.data));
      }
      setPublishing(false);
    }
  };

  const connectWordpress = async () => {
    window.location.href =
      "https://public-api.wordpress.com/oauth2/authorize?client_id=" +
      process.env.REACT_APP_WP_CLIENT_ID +
      "&redirect_uri=" +
      process.env.REACT_APP_WP_REDIRECT_URI +
      "&response_type=code&scope=global,posts&state=" +
      post._id;
  };

  return (
    <>
      <div className="modal-backdrop">
        <div className="modal modal-publish full-mobile">
          {page === 1 && (
            <>
              <button className="close-btn" onClick={close}>
                X
              </button>
              <div className="modal-content">
                <h3>How do you want to publish your blog post?</h3>

                <div className="publish-mode-container">
                  <div
                    className={"publish-mode-option" + (settings.publishMode === "wordpress" ? " active" : "")}
                    onClick={() => setValue("publishMode", "wordpress")}
                  >
                    <img src={wpIcon} alt="wp"></img>
                    <label>Wordpress integration</label>
                  </div>
                  <div
                    className={"publish-mode-option" + (settings.publishMode === "api" ? " active" : "")}
                    onClick={() => setValue("publishMode", "api")}
                  >
                    <img src={apiIcon} alt="api"></img>
                    <label>Custom API</label>
                  </div>
                </div>

                {settings.publishMode === "api" && (
                  <div>
                    <p>
                      We will call your custom API at the specified URL passing{" "}
                      <span
                        className="link"
                        style={{ marginTop: 20, marginBottom: 20, cursor: "pointer" }}
                        onClick={() => setOpenApiModal(true)}
                      >
                        following the paramenter
                      </span>
                    </p>
                    <p>Please ensure your custom API will publish the content on you own system</p>

                    <div className="label-row">
                      <label>
                        URL to call
                        <InfoIcon text="The URL of you custom API that will publish the article on your own system"></InfoIcon>
                      </label>
                      <input value={settings.urlApi} onChange={(e) => setValue("urlApi", e.target.value)}></input>
                      {errorUrl && <div className="alert-error">Please insert a valid URL</div>}
                    </div>

                    <div className="label-row">
                      <label>
                        Header variable name
                        <InfoIcon text="You can set this header for aithentication to your custom API"></InfoIcon>
                      </label>
                      <input value={settings.headerName} onChange={(e) => setValue("headerName", e.target.value)}></input>
                    </div>

                    <div className="label-row">
                      <label>
                        Header variable value
                        <InfoIcon text="Value of the header variable name specified before"></InfoIcon>
                      </label>
                      <input value={settings.headerValue} onChange={(e) => setValue("headerValue", e.target.value)}></input>
                    </div>

                    {openApiModal && <APIModal close={() => setOpenApiModal(false)}></APIModal>}

                    <div className="content-center">
                      <button className="big dark mt-30 mb-30" onClick={save}>
                        Publish
                      </button>
                    </div>
                  </div>
                )}
                {settings.publishMode === "wordpress" && (
                  <div className="content-center">
                    {user.settings.wordpressURL ? (
                      <div className="label-row" style={{ marginTop: 15, marginLeft: 10 }}>
                        Connected to{" "}
                        <a href={user.settings.wordpressURL} target="blank" className="link">
                          {user.settings.wordpressURL}
                        </a>
                        <div className="label-row mt-15">
                          <button onClick={connectWordpress} className="link">
                            Re-Connect Wordpress
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="label-row mt-15">
                        <button onClick={connectWordpress}>Connect Wordpress</button>
                      </div>
                    )}

                    <button className="big dark mt-30" onClick={save}>
                      Publish
                    </button>
                  </div>
                )}
              </div>
            </>
          )}

          {page === 2 && (
            <div className="modal-center">
              {publishing ? (
                <>
                  <h4 className="mt-60">We are publishing your post</h4>
                  <img src={upload} style={{ width: 350, marginBottom: -70 }} alt="upload"></img>
                  <p className="meta">Please wait</p>
                </>
              ) : (
                <>
                  <h4 className="mt-60">Yout post has been published</h4>

                  <img loop={false} src={uploadCompleted} style={{ width: 150 }} alt="upload-complete"></img>
                  <a href={post.publishedURL} target="_blank" rel="noreferrer" className="button link">
                    View Post
                  </a>

                  <p>If you want to schedule your next posts and put you blog on autopilot</p>
                  <button
                    className="mb-30 dark"
                    onClick={() => {
                      setPage(3);
                    }}
                  >
                    Set Auto Post
                  </button>

                  <button
                    onClick={() => {
                      close();
                    }}
                  >
                    Close
                  </button>
                </>
              )}
            </div>
          )}

          {page === 3 && (
            <>
              <div className="modal-content">
                <h3>When do you want to publish your post?</h3>

                <div className="publish-mode-container">
                  <div
                    className={"publish-mode-option" + (!settings.scheduledPublishing ? " active" : "")}
                    onClick={() => setValue("scheduledPublishing", "")}
                  >
                    <label>Disabled</label>
                  </div>
                  <div
                    className={"publish-mode-option" + (settings.scheduledPublishing === "daily" ? " active" : "")}
                    onClick={() => setValue("scheduledPublishing", "daily")}
                  >
                    <label>Daily</label>
                  </div>
                  <div
                    className={"publish-mode-option" + (settings.scheduledPublishing === "weekly" ? " active" : "")}
                    onClick={() => setValue("scheduledPublishing", "weekly")}
                  >
                    <label>Weekly</label>
                  </div>
                </div>

                <div className="content-center mt-30">
                  <p className="meta">
                    The publish is planned at 10AM PT. If there are articles created and not yet published it will be published,
                    otherwise a new one will be automatically created and published
                  </p>
                  <button className="dark" onClick={saveClose}>
                    Save and Close
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
