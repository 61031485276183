import { create } from "apisauce";
import packageJson from "../../package.json";

export const createApi = () => {
  const api = create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: {
      "Cache-Control": "no-cache",
      "app-version": packageJson.version,
    },
    timeout: 300000,
  });

  const setToken = (token) => {
    if (token) {
      const headerPrefix = "Bearer ";
      const header = "Authorization";
      api.addRequestTransform((request) => {
        request.headers[header] = `${headerPrefix}${token}`;
        return request;
      });
    }
  };

  const login = (email, password) => {
    return api.post(`/api/users/login`, { email, password });
  };

  const resetPassword = (userId, token, password) => {
    return api.post(`/api/users/resetPassword`, { userId, token, password });
  };

  const resetPasswordRequest = (email) => {
    return api.post(`/api/users/resetPasswordRequest`, { email });
  };

  const getUser = () => {
    return api.get(`/api/users/getUser`, {});
  };

  const saveSettings = (settings) => {
    return api.post(`/api/users/saveSettings`, settings);
  };

  const uploadPhoto = async (file, params) => {
    let formData = new FormData();
    formData.append("params", JSON.stringify(params));
    formData.append("file", file);

    return api.post(`/api/blogPost/uploadPhotoAdmin`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
      },
    });
  };

  const publish = (post) => {
    return api.post(`/api/blogPost/${post._id}/publish`, post);
  };

  const savePost = (post) => {
    return api.post(`/api/blogPost/${post._id ? post._id : ""}`, post);
  };

  const populatePost = (post) => {
    return api.post(`/api/blogPost/populate`, post);
  };

  const writePost = (post) => {
    return api.post(`/api/blogPost/writePost`, { post });
  };

  const elaboratePost = (post) => {
    return api.post(`/api/blogPost/elaboratePost`, { post });
  };

  const removePost = (id) => {
    return api.delete(`/api/blogPost/${id}`);
  };

  const createRandom = () => {
    return api.post(`/api/blogPost/createRandom`);
  };

  const getBlogPost = (id) => {
    return api.get(`/api/blogPost/${id}`);
  };

  const getBlogPosts = () => {
    return api.get(`/api/blogPost`);
  };

  const saveWordpressCode = (code) => {
    return api.post(`/api/users/saveWordpressCode`, { code });
  };

  const registerGoogle = (credential) => {
    return api.post(`/api/users/registerGoogle`, { credential });
  };

  // Old

  // const createCheckoutSession = (plan) => {
  //   return api.post(`/api/product/createCheckoutSession`, { plan });
  // };

  // const publishOnPinterest = (prod) => {
  //   return api.post(`/api/product/${prod._id}/publishOnPinterest`);
  // };

  // const getBlogPostsAdmin = (isProd) => {
  //   return api.get(`/api/blogPost/admin?isProd=${isProd}`);
  // };

  // const savePinterestCode = (code) => {
  //   return api.post(`/api/adminUsers/pinterestCode`, { code });
  // };

  // return functions for interface
  return {
    setToken,
    getBlogPost,
    getBlogPosts,
    writePost,
    savePost,
    populatePost,
    createRandom,
    login,
    resetPassword,
    resetPasswordRequest,
    getUser,
    saveSettings,
    uploadPhoto,
    elaboratePost,
    removePost,
    publish,
    saveWordpressCode,
    registerGoogle,

    // Old
    // generateMessage,
    // createCheckoutSession,
    // getProducts,
    // getProductsAdmin,
    // getProduct,
    // saveProd,
    // removeProd,
    // generateSitemap,
    // uploadProducts,
    // reprashe,
    // generateProduct,
    // validate,
    // publishOnPinterest,
    // getBlogPostsAdmin,
    // savePinterestCode,
    // publishAll,
    // importPosts,
    // findLinks,
  };
};

// Creates a singleton
export default createApi();
